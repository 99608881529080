import React from "react";
import "./index.css";
import loyalty2 from "../../assets/loyalty2.png";
import loyaltyDev1 from "../../assets/loyaltyDev1.png";
import loyaltyDev2 from "../../assets/loyaltyDev2.png";
import loyaltyDev3 from "../../assets/loyaltyDev3.png";
import { motion } from "framer-motion";
import { one, three, two } from "../../assets/number";

const LoyaltyDev = () => {
  return (
    <div>
      <div className="loyalty-types">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: -100 }}
          viewport={{ once: false, amount: 0.5 }}
          className="number-fact-group ">
          <div className="number-fact">
            <div className="loyalty-dev-num in">01</div>
            <span>Points</span>
          </div>
          <div className="number-fact">
            <div className="loyalty-dev-num">02</div>
            <span>Member Tiers</span>
          </div>

          <div className="number-fact">
            <div className="loyalty-dev-num in">03</div>
            <span>Rewards</span>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -300 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: -300 }}
          viewport={{ once: false, amount: 0.5 }}
          className="number-fact-group">
          <div className="number-fact">
            <div className="loyalty-dev-num in">04</div>
            <span>Referrals</span>
          </div>

          <div className="number-fact">
            <div className="loyalty-dev-num">05</div>
            <span>Wallets</span>
          </div>

          <div className="number-fact">
            <div className="loyalty-dev-num in">06</div>
            <span>Badges</span>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -500 }} // Adjust if necessary
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: -500 }} // Adjust if necessary
          viewport={{ once: false, amount: 0.5 }}
          className="number-fact-group">
          <div className="number-fact">
            <div className="loyalty-dev-num in">07</div>
            <span>Coupons</span>
          </div>

          <div className="number-fact">
            <div className="loyalty-dev-num">08</div>
            <span>Achievement</span>
          </div>

          <div className="number-fact">
            <div className="loyalty-dev-num in">09</div>
            <span>Code Scan</span>
          </div>
        </motion.div>

        <motion.img
          initial={{ opacity: 0.5, scale: 0.3, x: 0 }}
          whileInView={{ opacity: 1, scale: 1, x: 50 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0.5, scale: 0.3 }}
          viewport={{ once: false, amount: 0.5 }}
          src={loyalty2}
          className="mr-20"
          alt="Loyalty Overview"
        />
      </div>

      <div>
        <h3 className="loyalty-app-dev-title">
          Loyalty Application Development
        </h3>
        <div className="loyalty-application-dev pr-[70px] ">
          <div className="single-app-dev ">
            <motion.img
              initial={{ opacity: 0.5, scale: 0.3, x: 0 }}
              whileInView={{ opacity: 1, scale: 0.7, x: 50 }}
              transition={{ duration: 1.5, ease: "easeInOut" }}
              exit={{ opacity: 0.5, scale: 0.3 }}
              viewport={{ once: false, amount: 0.5 }}
              src={loyaltyDev1}
              className="pr-5"
              alt="Cash Back"
            />
            <div className="loyal-dev-btn-ctn ">
              <motion.img
                initial={{ x: 40 }}
                src={one}
                style={{ height: "60px", left: "0" }}
                className="loyal-dev-btm-num"
                alt="one"
              />
              <div>
                <div className="title-block">Cash Back</div>{" "}
              </div>
            </div>{" "}
          </div>

          <div className="single-app-dev">
            <motion.img
              initial={{ opacity: 0.5, scale: 0.3, x: 0 }}
              whileInView={{ opacity: 1, scale: 0.6, x: 50 }}
              transition={{ duration: 1.5, ease: "easeInOut" }}
              exit={{ opacity: 0.5, scale: 0.3 }}
              viewport={{ once: false, amount: 0.5 }}
              src={loyaltyDev2}
              alt="Offer & Campaigns"
            />{" "}
            <div className="loyal-dev-btn-ctn">
              <motion.img
                initial={{ x: 40 }}
                src={two}
                style={{ height: "60px", left: "0" }}
                className="loyal-dev-btm-num"
                alt="one"
              />
              <div>
                <div className="title-block">Offer & Campaigns</div>
              </div>
            </div>{" "}
          </div>

          <div className="single-app-dev">
            <motion.img
              initial={{ opacity: 0.5, scale: 0.3, x: 0 }}
              whileInView={{ opacity: 1, scale: 0.6, x: 50 }}
              transition={{ duration: 1.5, ease: "easeInOut" }}
              exit={{ opacity: 0.5, scale: 0.3 }}
              viewport={{ once: false, amount: 0.5 }}
              src={loyaltyDev3}
              alt="Digital Stamp Cards"
            />
            <div className="loyal-dev-btn-ctn">
              <motion.img
                initial={{ x: 40 }}
                src={three}
                style={{ height: "60px", left: "0" }}
                className="loyal-dev-btm-num"
                alt="one"
              />
              <div>
                <div className="title-block">Digital Stamp Cards</div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyDev;
