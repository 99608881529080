import React from "react";
import "./index.css";
import laptop from "../../assets/16-9-24/image 3.png";
import mobile from "../../assets/16-9-24/Mobile.png";
import hexagon from "../../assets/16-9-24/Payment Solution Bg.png";
import { motion } from "framer-motion";

const Feature = () => {
  const featureCards = [
    { title: "Leasing Management", className: "gradient ml-5" },
    { title: "Financial Report", className: "white" },
    { title: "Billing & Invoicing", className: "gradient ml-5" },
    { title: "Maintenance Service", className: "white" },
    { title: "Reporting", className: "gradient ml-5" },
    { title: "Announcement", className: "white" },
    { title: "Surveys", className: "gradient ml-5" },
    { title: "Payment Gateway", className: "white" },
  ];

  return (
    <div className="feature-container">
      <h3 className="feature-header  ">
        Features of Origin Property-Tenant Management System
      </h3>
      <div
        className="xl:pl-52 xl:pr-52"
        style={{
          justifyContent: "space-between",
          backgroundImage: { hexagon },
          backgroundPositionY: "0",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}>
        <div className="flex flex-col md:flex-row">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {featureCards.slice(0, 4).map((card, index) => (
              <motion.div
                initial={{ x: -10, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: false, amount: 0.6 }}
                transition={{ duration: 0.7, delay: 0.2 }}
                exit={{ x: -10, opacity: 0 }}
                key={index}
                className={`feature min-w-[300px] image-1 ${card.className}`}>
                {card.title}
              </motion.div>
            ))}
          </div>

          <div className="m-5 hidden md:block">
            <motion.img
              initial={{ x: -10, opacity: 0, y: 200 }}
              whileInView={{ x: 10, opacity: 1, y: 0 }}
              viewport={{ once: false, amount: 0.6 }}
              transition={{ duration: 0.7, delay: 0.2 }}
              exit={{ x: -10, opacity: 0 }}
              src={mobile}
              width="auto"
              height={259}
              alt="mobile-img"
              style={{
                visibility: "visible",
                position: "relative",
                zIndex: 999,
              }}
              className="image-4"
            />
            <motion.img
              initial={{ x: -10, opacity: 0, y: -150 }}
              whileInView={{ x: 0, opacity: 1, y: -150 }}
              viewport={{ once: false, amount: 0.6 }}
              transition={{ duration: 0.7, delay: 0.2 }}
              exit={{ x: -10, opacity: 0 }}
              src={laptop}
              width={250}
              height="auto"
              alt="tablet-img"
              className="image-5"
            />
          </div>
          <div>
            {featureCards.slice(4).map((card, index) => (
              <motion.div
                initial={{ x: 10, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: false, amount: 0.6 }}
                transition={{ duration: 0.7, delay: 0.2 }}
                exit={{ x: 10, opacity: 0 }}
                key={index}
                className={`feature min-w-[300px]  ${card.className}`}>
                {card.title}
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
