import React from "react";
import "./index.css";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import lightblueHeading from "../../assets/lighting_header.png";
const HeroHeader = () => {
  return (
    <div className="hero-section-header">
      <img src={lightblueHeading} alt="heading" />
      <p>Evaluate your digital transformation, landscape with </p>
      <h3 className="hero-section-main">
        OBS's <span>Digital Solution !</span>
      </h3>
      <Link to="./solutions">
        <button className="explore-more-btn">
          Explore More <ArrowRightOutlined />
        </button>
      </Link>
    </div>
  );
};

export default HeroHeader;
